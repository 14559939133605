import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FeatureIcon = styled.div`
  ${tw`
    ml-4
    flex
    items-center
    justify-center
    h-10
    w-10
    rounded-full
    bg-white
    text-green-500
  `}
  svg {
    ${tw`
      h-4
      w-4
      fill-current
    `}
  }
`

export const FeatureWrapper = styled.div`
  ${tw`
    mt-6
    md:mt-12
    md:mt-0
  `}
`

export const FeatureText = styled.div`
  ${tw`
    mt-2
    text-left
  `}
  h3 {
    ${tw`
      pl-3
      uppercase
      text-sm
      leading-6
      font-medium
      text-green-500
      border-l-4 
      border-green-500
    `}
  }
  h3.ui-editor {
    ${tw`
      text-purple-500
      border-purple-500
    `}
  }
  h3.authentication {
    ${tw`
      text-blue-500
      border-blue-500
    `}
  }
  h3.users-management {
    ${tw`
      text-green-500
      border-green-500
    `}
  }
  h3.pricing {
    ${tw`
      text-pink-600
      border-pink-600
    `}
  }
  h3.enterprise-connections {
    ${tw`
      text-pink-600
      border-pink-600
    `}
    color: #7872FB;
    border-color: #7872fb;
  }
  p {
    ${tw`
      pl-4
      mt-2
      text-base
      leading-6
      text-gray-500
    `}
  }

  a.call-to-action {
    ${tw`
      pl-4
      mt-2
      text-base
      text-teal-500
      cursor-pointer
    `}
  }
  a {
    ${tw`
      pl-0
      text-base
      text-gray-700
      hover:text-gray-600
      cursor-pointer
    `}
  }
  a.users-management {
    ${tw`
      text-green-500
      hover:text-green-400
    `}
  }
  a.pricing {
    ${tw`
      text-pink-500
      hover:text-pink-400
    `}
  }
  a.authentication {
    ${tw`
      text-blue-500
      hover:text-blue-400
    `}
  }
`

export const FeatureStyle = styled.div`
  &.one-fifth {
    ${tw`
      md:w-1/5
      order-last
      md:order-first
    `}
  }
  &.fourth-fifths {
    ${tw`
      md:w-4/5
      order-last
      md:order-first
    `}
  }
  &.half {
    ${tw`
      md:w-1/2
      order-last
      md:order-first
    `}
  }
`
