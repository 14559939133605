import React from 'react'
import { FeatureIcon, FeatureStyle, FeatureText, FeatureWrapper } from './style'

type FeatureProps = {
  icon?: any
  title: string
  description: any
  call_to_action?: string
  className: string
  slug?: string
  path?: string
}

const Feature = ({
  icon,
  title,
  description,
  call_to_action,
  className,
  slug,
  path,
}: FeatureProps) => {
  return (
    <FeatureStyle className={className}>
      <FeatureWrapper>
        {icon && <FeatureIcon>{icon}</FeatureIcon>}
        <FeatureText>
          <h3 className={slug}>{title}</h3>
          <div>{description}</div>
          {path !== undefined && (
            <a className={`${slug} call-to-action`} href={path}>
              {call_to_action}
            </a>
          )}
        </FeatureText>
      </FeatureWrapper>
    </FeatureStyle>
  )
}

export default Feature
